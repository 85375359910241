import { Card, StepProps, Steps, Tooltip } from "antd";
import { ApprovalAction, ApprovalRequest, ApproverRole, WorkflowStep } from "../api/models";
import { ReactNode, useContext } from "react";
import Utils from "../util/util";
import { ConfiguratorContext } from "../context";
import {ExclamationCircleFilled, CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const WorkflowProgress = (props: {
  workflow: WorkflowStep[] | undefined
  hideDescription? : boolean
  reservation?: boolean | undefined
}) => {

  const configurator = useContext(ConfiguratorContext);
  const isInternalSales = configurator.isInternalSales();
  const {workflow, hideDescription, reservation} = props;

  const getTitle = (approvals: WorkflowStep[], idx: number) => {
    const suffix = (approvals?.[1]?.approval?.approvalType === ApprovalRequest.QUOTE_APPROVAL && idx === (approvals.length - 1)) ? " (Approved)" : "";

    const stepName = !!reservation 
    ? (idx === (approvals.length - 1) ? "Reservation" : approvals?.[idx].step.replace(/Quote Approval/i, "Quote Reservation") )
    : approvals?.[idx].step;

    return Utils.snakeCaseToFirstLetterCapitalized(stepName.replace(/ /g, "_")) + suffix;
  };

  const getSubTitle = (s: WorkflowStep): ReactNode => {
    return s.approval?.actionNotes && isInternalSales && <Tooltip title={s.approval?.actionNotes ? `Note: ${s.approval?.actionNotes}` : ""}><ExclamationCircleFilled style={{ color: "#1677ff", marginRight: "5px" }} /></Tooltip>;
  };

  type StepStatus = 'finish' | 'process' | 'error';

  const getStatus = (a: WorkflowStep): StepStatus => {
    return a?.completed ? (a?.approval?.action === "REJECTED" ? "error" : "finish") : "process";
  };

  const getActionTime = (w: WorkflowStep) => {
    if (w?.approval?.actionedAt) {
      return `At: ${dayjs(w?.approval?.actionedAt == null ? w?.approval?.createdAt : w?.approval?.actionedAt).format("M/D/YY, h:mm a")}`
    }
    return (w.completed ? "" : "Processing");
  }

  const getActionColor = (s: WorkflowStep) => {
    return s?.approval?.action ? (s?.approval?.action === ApprovalAction.APPROVED ? "green" : "red") : "#1677ff";
  }

  const getActionIcon = (s: WorkflowStep) => {
    return (s.approval.action === ApprovalAction.APPROVED ? 
    <Tooltip title="Approved"><CheckCircleOutlined style={{fontSize: "12px", marginRight: "5px", color: getActionColor(s)}} /></Tooltip>
    :
    <Tooltip title="Rejected"><CloseCircleOutlined style={{fontSize: "12px", marginRight: "5px", color: getActionColor(s)}} /></Tooltip>);
  }

  const getCardTitle = (workflow: WorkflowStep[], idx: number, s: WorkflowStep) => {
    return (
      <>
        {getSubTitle(s)}
        <span>{getTitle(workflow, idx)}</span>

      </>
    );
  }

  const getItems = () => {

    let items: StepProps[] = workflow?.map((s, idx) => {return {
      title: 
      <>
        {!hideDescription ? <Card title={getCardTitle(workflow, idx, s)} bordered={false} style={{ maxWidth: 140, minWidth: 120, marginTop: "10px" }} rootClassName="step-card">
          {s?.approval && <>
            {s?.approval?.actionedAt && getActionIcon(s)}
            <span style={{fontWeight: "500", fontSize: "12px", color: getActionColor(s)}}>
              <span>{s?.approval?.actionedBy?.name}</span>
            </span>
            <div key={`${idx}-actionedAt`} style={{fontWeight: "500", fontSize: "12px", color: getActionColor(s)}}>{getActionTime(s)}</div>
            
          </>}
        </Card>
        :
        getTitle(workflow, idx)
        }
      </>,
      status: getStatus(s),
    }}) || []
    return items;
  }

  const isStepApprover = (step:WorkflowStep | undefined) : boolean => {
    if ( !step ) return false;

    const isEngineeringApproval = configurator.isEngineering() && [ ApproverRole.ENGINEERING ].includes( step.approval.approverRole );
    const isProducurementApproval = configurator.isProcurement() && [ ApproverRole.PROCUREMENT ].includes( step.approval.approverRole );
    const isFinanceApproval = configurator.isFinance() && [ ApproverRole.FINANCE ].includes( step.approval.approverRole );
    const isSalesDeskApproval = configurator.isSalesDesk() &&  [ ApproverRole.SALES_DESK, ApproverRole.SALES ].includes( step.approval.approverRole );
    const isSalesManagerApproval = configurator.isSalesManager() &&  [ ApproverRole.SALES_MANAGER ].includes( step.approval.approverRole );
    const isReleaseEngineeringApproval = configurator.isReleaseEngineering() && [ ApproverRole.RELEASE_ENGINEERING ].includes( step.approval.approverRole );
    const isSalesApproval = configurator.isInternalSales() &&  [ ApproverRole.SALES ].includes( step.approval.approverRole );

    return configurator.isAdmin() ||
      isEngineeringApproval ||
      isProducurementApproval ||
      isFinanceApproval ||
      isSalesDeskApproval ||
      isSalesManagerApproval ||
      isReleaseEngineeringApproval ||
      isSalesApproval;
  }

  const handleChangeStep = (stepNo:number) => {

    const step = workflow?.[stepNo];

    if ( !step?.approval ) return;

    if ( step?.completed ) return;

    const isApprover = isStepApprover( step);
    if ( isApprover ) {
      window.open( `/approvals/${step.approval.id}`, "_blank"); 
    }
  }

  const activeStep = workflow?.find( w => !!w.approval && !w.completed );

  return (
    <>
    <style>
      {`
        .workflow-steps .ant-steps-item-content {
          width: 170px !important;
          margin-top: 6px !important;
          margin-left: -30px !important;
        }

        .step-card {
          box-shadow: 0 0.4px 1.5px 0.5px rgba(0, 0, 0, 0.2) !important;
        }

        .step-card .ant-card-head {
          font-size: 12px;
          justify-content: normal !important;
          max-width: 180px;
          padding-left: 4px;
          padding-right: 4px;
          min-height: 40px;
          flex-direction: unset;
        }

        .step-card .ant-card-head-title {
          white-space: normal;
        }
        
        .step-card .ant-card-body {
          font-size: 12px;
          justify-content: normal !important;
          padding: 0px !important;
          padding-left: 2px !important;
          padding-right: 2px !important;
        }
      `}
    </style>
      {!!workflow?.length ? 
        <Steps 
          data-testid="ApprovalTransitionInfoSteps"
          items={
            getItems()
          }
          rootClassName="workflow-steps"
          style={{marginTop: "1rem"}}
          size="small"
          responsive={true}
          labelPlacement="vertical"
          onChange={ isStepApprover(activeStep) ? handleChangeStep : undefined}
        />
        :
        <></>
      }
      </>
  );
}

export default WorkflowProgress;
