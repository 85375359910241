import "./AssemblySelectionTable.css"
import { Alert, Button, Col, Input, notification, Row, Space, Table, TablePaginationConfig} from "antd";
import {Assembly, CategoryIdAssembliesIdMap, CategoryMetadata, Category, QuoteAssemblyExceptionType, CustomOptionType, BaseCategory, AXIOS_CANCEL_MSG} from "../../api/models";
import {
  EditOutlined 
} from "@ant-design/icons";
import Utils from "../../util/util";
import {ColumnType} from "antd/lib/table";
import {useMemo, useContext, useEffect, CSSProperties, useState, ChangeEvent, useRef, useCallback} from "react";
import {ConfiguratorContext, CustomOptionsContext, CustomOptionsContextType} from "../../context";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import axios, {CancelTokenSource} from "axios";
import {useIntl} from "react-intl";
import Title from "antd/lib/typography/Title";
import RuleDebugOutputModal from "../RuleDebugOutputModal";
import BMButton from "../BMButton";
import {MenuItemType} from "antd/es/menu/hooks/useItems";
import {isAssembly, QuoteAssemblyExceptionContext, QuoteAssemblyExceptionContextType} from "../../pages/configurator";
import AssemblyExceptionButtonModal from "./AssemblyExceptionButtonModal";
import EditCustomOptionButtonModal from "../EditCustomOptionButtonModal";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { debounce } from "lodash";
import { FilterValue, SorterResult } from "antd/es/table/interface";

interface ExtAssembly extends Assembly {
  incompatible?: boolean
}
export interface SelectionInfo {
  key:string,
  option:ExtAssembly | CustomOptionType
}

export function asExtAssembly(option: Assembly | CustomOptionType | undefined) : ExtAssembly | undefined {
  return isAssembly(option) ? option as ExtAssembly : undefined;
}
export function asCustomOption(option: Assembly | CustomOptionType | undefined) : CustomOptionType | undefined {
  return !isAssembly(option) ? option as CustomOptionType : undefined;
}

const warningTextStyle = {color: "red", fontWeight: 'bold'};
const infoTextStyle = {color: "blue", fontWeight: 'bold'};

const DEFAULT_PAGE_SIZE = 5;
const AssemblySelectionTable = (props: {
  readOnly?: boolean
  loading?: boolean
  disabled?: boolean
  selectedCategory: Category | undefined
  onSelectOption: (c:BaseCategory, o:Assembly | CustomOptionType)=>void
  onClearSelections: (c:BaseCategory)=>void
  filterOptionsQuery: string | undefined
  optionNotes:Record<number,string>
  onUpdateOptionNotes:(a:Assembly | undefined, note:string | undefined)=>void
  percentDiscount: number | undefined
}) => {
  const { 
    selectedCategory,
    filterOptionsQuery,
    optionNotes,
    percentDiscount,
  } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const isEngineering = configurator.isEngineering();
  const isAdmin = configurator.isAdmin();

  const [optionLst, optionLstAsync] = useAsyncState<ExtAssembly[]>();

  const { quoteAsync, loadQuoteOnly, adminView, selectedOptions, selectedCustomOptions, selectedModel} = useQuoteContext();
  const quote = quoteAsync?.val;
  const revisionId = quote?.displayRevisionId;
  const modelId = selectedModel?.modelInfo.id;

  const { quoteAssemblyExceptionLstAsync, loadQuoteAssemblyExceptions } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const quoteAssemblyExceptionLst = quoteAssemblyExceptionLstAsync?.val;

  const { customOptionLstAsync, loadCustomOptions } = useContext<CustomOptionsContextType>(CustomOptionsContext);
  const customOptionLst = customOptionLstAsync?.val?.filter( co => co.category?.id === selectedCategory?.id );
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [optionsSearch, setOptionsSearch] = useState<string | undefined>();
  const [tableKey, setTableKey] = useState<number>(0);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    if ( selectedCategory && modelId ) {
      loadCategoryOptions(optionLstAsync, modelId, selectedCategory.id, selectedOptions, selectedCustomOptions);
    }
    setOptionsSearch(undefined);

    //this is a hack to reset the table filters by forcing a table re-render
    setTableKey( tableKey + 1);
  }, [selectedCategory, modelId, quoteAssemblyExceptionLst ])

  const {
    isOrder,
    isPendingApproval,
  } = useMemo( () => Utils.getQuoteState(configurator, quoteAsync), [quote]);

  const loadCategoryOptions = useCallback(debounce( async (optionLstAsync:AsyncState<ExtAssembly[]>, modelId:number, categoryId:number, selectedOptions:CategoryIdAssembliesIdMap | undefined, selectedCustomOptions:CustomOptionType[] | undefined) : Promise<Assembly[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      optionLstAsync.setLoading();

      const resp = await configurator.api.fetchCategoryOptions(modelId, categoryId, { 
      selections: Object.values(selectedOptions || {}).flat(),
        customOptions: selectedCustomOptions?.map(co => co.id),
        quoteRevisionId:revisionId, 
        percentDiscount 
      },  cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      //if an option is filtered out, mark it as incompatible
      const categorySelectionIds = selectedOptions?.[ categoryId ];
      const filteredAssemblies = resp.data.filteredAssemblies?.filter( asm => categorySelectionIds?.includes( asm.id ) )
      .map( asm => ({...asm, incompatible:true }));

      //de-obsolete exceptions
      const obsoleteExceptionSet = new Set( quoteAssemblyExceptionLst?.filter( a => a.type === QuoteAssemblyExceptionType.OBSOLETE )
                                           .map( a => a.assembly.id ) );
      const options = ( resp.data.options || []  ).map( asm => obsoleteExceptionSet.has( asm.id ) ? {...asm, obsoletedAt: undefined }  : asm )

      optionLstAsync.setDone([
        ...(options),
        ...(filteredAssemblies || [] )
      ]);
      return resp.data.options;

    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load category options. " + errorMsg });
        optionLstAsync.setFail(e.message);
      }
    }

    return;
  }, 750), []);



  const selectedOptionLst = ( selectedCategory && selectedOptions?.[ selectedCategory.id ] ) || [];
  const selectedCustomOptionLst = selectedCustomOptions?.filter(co => co.category?.id === selectedCategory?.id ) || [];

  const isValidLeadTime = !Utils.isWithinLeadTime(quote?.productionDate, selectedCategory?.leadTimeDays);
  const overrideLeadTime = isAdmin || isEngineering;

  const saveUserSelection = async (quoteRevisionId:number | undefined, selection:SelectionInfo | undefined) : Promise<void> => {
    if ( !quoteRevisionId ) return;
    if ( !selection ) return;

    try {

      await configurator.api.saveUserSelection(quoteRevisionId, {
        assemblyId: asExtAssembly(selection.option)?.id,
        customOptionId: asCustomOption(selection.option)?.id,
      });

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      console.log( "Failed to load category options. " + errorMsg );
    }

    return;
  }



  const getSortKey = (selectionInfo:SelectionInfo | undefined) : string |undefined => {
    if (isAssembly( selectionInfo?.option ) ) {
      const asm = selectionInfo?.option as ExtAssembly;
      const lbl = ( !!asm.label?.length )
        ? asm.label
        : asm.bomDescription 

        return lbl + ", " + asm.bom;
    }
    else {
      const co = selectionInfo?.option as CustomOptionType;
      return co.content;
    }
  }

  const getColumnForMetadata = ( md:CategoryMetadata ) : ColumnType<SelectionInfo> => {

    const metadataValues = optionLst?.map( asm => {
        const m = asm.metadata.find( md1 => md.id == md1.categoryMetadata.id );
        return Utils.getMetadataValue(m);
      })
      .filter( a => a !== undefined ) as Array<string|number>;

    //get distinct
    const allFilters = Array.from( new Set( metadataValues ) );

    return {
      key: md.id,
      title: md.name,
      width: 150,
      filters: allFilters.map((f) => {
        return { value: f, text: f };
      }),
      render: (s:SelectionInfo) => {
        const m = s.option?.metadata?.find( md1 => md1.categoryMetadata.id == md.id);
        return m == null ? "" : Utils.getMetadataValue(m);
      },
      onFilter: (v, s:SelectionInfo) => {
        const m = s.option?.metadata?.find( md1 => md1.categoryMetadata.id == md.id );
        return Utils.getMetadataValue(m) === v;
      },
      sorter: (a, b) => {

        const [ aVal, bVal ] = [ a.option, b.option ]
        .map( asm => asm?.metadata?.find( md1 => md1.categoryMetadata.id == md.id) )
        .map( md => Utils.getMetadataValue( md ) );

        if (typeof aVal === "number" && typeof bVal === "number" ) {
          return aVal - bVal;
        }

        //convert any numbers or undefined to string
        const [ vA, vB ] = [aVal, bVal ]
        .map( v => v || "" )
        .map( v => ( typeof v === "number" ) ? String( v ) : v );

        return vA.localeCompare(vB);

      },
    };

  };

  const categoryMatchesFilter = (category: BaseCategory | undefined ) : boolean => {
    if ( !category ) return false;
    if ( !filterOptionsQuery ) return true;

    const searchFilter = Utils.splitByWhitespacePreservingQuotes(filterOptionsQuery);
    return Utils.searchValue( searchFilter, category.name );
  }

  const getUserOptionsFilter = () : ((o:Assembly) => boolean)=> {
    if ( !selectedCategory ) return () => true;
    if ( !filterOptionsQuery ) return () => true;
    if ( categoryMatchesFilter(selectedCategory) ) return () => true;


    return (o:Assembly) => {

      const asmSearch =  [
        o.bom,
        o.label,
        o.bomDescription,
      ]
      .filter( v => v )
      .join( " ");

      const searchFilter = Utils.splitByWhitespacePreservingQuotes(filterOptionsQuery);
      return Utils.searchValue( searchFilter, asmSearch );

    }

  }

  const getObsoleteOptionsFilter = () : ((o:Assembly) => boolean)=> {
    if ( isEngineering || isAdmin ) return () => true;

    return (o:Assembly) => ( selectedOptionLst.includes(o.id) || !o.obsoletedAt );
  }

  const getLeadTimeAlertMsg = () : string | undefined => {
    if ( isValidLeadTime ) return;

    if ( overrideLeadTime ) {
      return `Warning! Changes are within ${selectedCategory?.leadTimeDays} days of the Production Date.`;
    }
    else {
      const rslt = new Array<string>();
      rslt.push( `Selections in this category cannot be changed within ${selectedCategory?.leadTimeDays} days of the Production Date.` );
      const primaryEngineer = quote?.salesTeam?.engineers?.find(v=>v);
      if ( primaryEngineer ) {
        rslt.push(`Please reach out to ${primaryEngineer.name} (${primaryEngineer.email}) if you need additional assistance.`);
      }
      return rslt.join( " " );
    }
  };

  const getAssemblyExceptionDisabledMsg = () : string | undefined => {

    return !quote?.displayRevisionId ? "The quote is still loading."
      : !selectedCategory ? "A category must be selected."
      : undefined;

  }

  const getCategoryDisabledMsg = () : string | undefined => {

    return !quote?.displayRevisionId ? "The quote is still loading."
      : !selectedCategory ? "A category must be selected."
      : ( !(overrideLeadTime || isValidLeadTime) ) ? getLeadTimeAlertMsg()
      : ( isPendingApproval && props.readOnly ) ? "Assemblies cannot be modified while approval is pending."
      : ( isOrder && props.readOnly ) ? "A change order is required to modify assemblies."
      : props.readOnly ? "The quote is currently read-only" 
      : undefined;

  }

  const getDebugDisabledMsg = () : string | undefined => {
    const disabledMessage = getCategoryDisabledMsg();
    return !!disabledMessage ? disabledMessage
            : !(isAdmin || isEngineering) ? "Only engineering can clear"
            : undefined;
  }

  const isCategoryDisabled = () : boolean => {
    return !!getCategoryDisabledMsg()
  }

  const getCategoryWarningMsg = () : string | undefined => {
    if ( overrideLeadTime && !isValidLeadTime ) return getLeadTimeAlertMsg();

    return;
  }

  const isCategoryWarning = () : boolean => {
    return !!getCategoryWarningMsg()
  }

  const isRowDisabled = (s:SelectionInfo) :boolean => {

    if( isCategoryDisabled() ) return true;

    const asm = asExtAssembly(s.option);
    if ( !(isEngineering || isAdmin ) && asm?.obsoletedAt ) return true;
    if ( asm?.incompatible ) return true;

    const co = asCustomOption(s.option);
    if ( co?.approved === false && !co.included ) return true;

    return false;
  }

  const isSelected = (s:SelectionInfo):boolean => {
    const asm = asExtAssembly(s.option);
    const co = asCustomOption(s.option);

    const isSelectedAssembly = asm && selectedOptionLst.includes(asm.id)
    const isSelectedCustomOption = co && selectedCustomOptionLst.map( co => co.id).includes(co.id);
    return isSelectedAssembly || isSelectedCustomOption || false;
  }

  const obsoleteLastComparison = (a:SelectionInfo,b:SelectionInfo) => {
    const aObsoleted = !!a.option['obsoletedAt'];
    const bObsoleted = !!b.option['obsoletedAt'];

    if (!aObsoleted && bObsoleted) {
      return -1;
    }
    if (aObsoleted && !bObsoleted) {
      return 1;
    }
    return 0;
  };

  const labelComparision = (a:SelectionInfo,b:SelectionInfo) => (getSortKey(a) || "").localeCompare( (getSortKey(b) || "" ));

  const buildSelectionInfo = (o:CustomOptionType | ExtAssembly ) :SelectionInfo => {
    const asm = asExtAssembly(o);
    if( asm ) {
      return {key: `assembly-${asm.id}`, option:asm};
    }

    const co = o as CustomOptionType;
    return {key: `customOption-${co.id}`, option:co};
  }

  const customSelections:SelectionInfo[] | undefined = customOptionLst?.map(buildSelectionInfo);

  const assemblySelections:SelectionInfo[] | undefined = optionLst?.filter(getUserOptionsFilter())
    .filter(getObsoleteOptionsFilter())
    .map(buildSelectionInfo);


  const getAssemblySearch = (asm:ExtAssembly | undefined ) : string[] | undefined => {
    if ( !asm ) return;

    return [
      asm.bom,
      Utils.splitByWhitespacePreservingQuotes( asm.label ),
      Utils.splitByWhitespacePreservingQuotes( asm.bomDescription ),
       asm.metadata.map( m => m.valueText ?? m.valueNumeric ?? m.valueDecimal ).filter(v=>v).map(v => String(v)),
      asm.obsoletedAt ? "obsolete" : undefined,
      asm.incompatible ? "incompatible" : undefined,
    ]
    .filter( v => v )
    .flat() as string[];

  }
  const getCustomOptionSearch = (co:CustomOptionType | undefined ) : string[] | undefined => {

    if ( !co ) return;

    return [
      Utils.splitByWhitespacePreservingQuotes( co.content ),
      Utils.splitByWhitespacePreservingQuotes( co.note ),
      String( co.metadata?.map( m => m.valueText ?? m.valueNumeric ?? m.valueDecimal )  ),
    ]
    .filter( v => v )
    .flat() as string[];

  }
  const filterSearch = (selection:SelectionInfo) : boolean => {
    if ( !optionsSearch ) return true;

    if ( isSelected( selection ) ) return true;

    const asm = asExtAssembly(selection.option);
    const searchSelection = asm ? getAssemblySearch(asm) : getCustomOptionSearch( asCustomOption(selection.option) );
    if (!searchSelection) return false;

    const searchFilter = Utils.splitByWhitespacePreservingQuotes(optionsSearch);
    return Utils.searchValue( searchFilter, searchSelection.join(" ") );
  }

  const dataSource:SelectionInfo[] | undefined = [ ...assemblySelections || [], ...customSelections || [] ]
    .sort(labelComparision)
    .sort(obsoleteLastComparison)
    .filter(filterSearch)
    .reduce(Utils.bisect(isSelected), [new Array<SelectionInfo>(), new Array<SelectionInfo>()])
    .flat();

  const obsoletedOptions: SelectionInfo[] = dataSource.filter(s => s.option?.['obsoletedAt']);

  const selectedRows = dataSource?.filter( isSelected );
  const disabledSelectedRows = selectedRows?.filter( isRowDisabled );

  const handleSelectRow = async (s:SelectionInfo) => {
    if (!selectedCategory) return;
    if (!(overrideLeadTime || isValidLeadTime)) return;
    if (isRowDisabled(s)) return;

    saveUserSelection(quote?.displayRevisionId, s );

    props.onSelectOption(selectedCategory, s.option);
  };

  //if category allows multiple or there are multiple selected, allow multiple selection
  const showMultiple = selectedCategory?.allowMultiple || selectedRows.length > 1;

  const showMultipleWarning = showMultiple && !selectedCategory?.allowMultiple

  const handleClearDisabled = () => {
    if (!selectedCategory) return;

    selectedRows?.filter( isRowDisabled ).forEach( s => {
       props.onSelectOption(selectedCategory, s.option );
    });
  }

  const handleAddCustomOption = async (customOption:CustomOptionType)  => {
    await loadCustomOptions?.();
    await loadQuoteOnly?.();

    if (!selectedCategory) return;
    props.onSelectOption(selectedCategory, customOption);
  }

  const handleEditCustomOption = async (_customOption:CustomOptionType)  => {
    await loadCustomOptions?.();
    await loadQuoteOnly?.();
  }

  const handleUpdatedAssemblyException = () => {
    loadQuoteAssemblyExceptions?.(quote?.quoteId);
  }

  const handleClear = () => {
    if (!selectedCategory) return;
    props.onClearSelections(selectedCategory);
  }

  const handleChangeSearchFilter = (e:ChangeEvent<HTMLInputElement>) => {
    setOptionsSearch( e.target.value );
  }

  const handleTableChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, _sorter: SorterResult<SelectionInfo> | SorterResult<SelectionInfo>[]) => {
    setPagination(pagination);
  };

  const optionActionItems = new Array<MenuItemType>();
  optionActionItems.push( {
    key: "clearBtn",
    label: <BMButton type="text" className="ghostBmButton"
      disabled={!!getCategoryDisabledMsg()}
      onDisabledClick={() => notifyDisabled(getCategoryDisabledMsg())}
      onClick={handleClear}>Clear</BMButton>
  } );
  optionActionItems.push( {
    key: "debugBtn",
    label:
        <RuleDebugOutputModal
          category={selectedCategory}
          type="text"
          obsoletedOptions={obsoletedOptions}
        />
  } );

  const metadataColumns:ColumnType<SelectionInfo>[] = 
    selectedCategory?.metadata?.filter( md => adminView || md.visibleInConfigurator )
    .sort((a,b) => (a.sortOrder || 0 ) - ( b.sortOrder || 0 ) )
    .map( md  => getColumnForMetadata( md ) ) || [];

  const labelStyle =  { wordWrap: "break-word", wordBreak: "break-word", width: "30rem" } as CSSProperties;

  const columns:ColumnType<SelectionInfo>[] = [
    {
      key: selectedCategory?.categoryId,
      title: "Name",
      fixed: "left",
      render: (s: SelectionInfo) => {
        const asm = asExtAssembly(s.option);
        const co = asCustomOption(s.option);
        if ( asm ) {
          return <AssemblyDetail 
            labelStyle={labelStyle}
            assembly={asm} 
            note={optionNotes[asm.id]} 
            onUpdateNote={(note) => props.onUpdateOptionNotes(asm, note) } />
        }
        else if(co) {
          return <CustomOptionDetail 
            category={selectedCategory}
            labelStyle={labelStyle}
            disabled={isRowDisabled(buildSelectionInfo(co))}
            customOption={co} 
            onChange={handleEditCustomOption} />
        }
      },
      sorter: labelComparision
    },
    ...metadataColumns
  ];

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  return <>

    <style>
      {`
        .ant-table-cell {
          background-color: white !important;
        }
      `}
    </style>

        <Title level={5}>{Utils.stripSortingPrefix(selectedCategory?.name)} Options</Title>
    <Row justify={"space-between"} align="middle" style={{width:"100%", marginBottom: ".5rem"}}>
      <Col>
            <Space direction="horizontal">
              <Input value={optionsSearch} onChange={handleChangeSearchFilter} placeholder="Filter options" allowClear style={{minWidth: "20rem" }} />
            </Space>
      </Col>
      <Col>
        <Space direction="horizontal">

          {(isEngineering || isAdmin ) &&
          <AssemblyExceptionButtonModal type="primary" 
            onAdd={handleUpdatedAssemblyException} 
            onDelete={handleUpdatedAssemblyException} 
            disabled={!!getAssemblyExceptionDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getAssemblyExceptionDisabledMsg())}
            selectedCategory={selectedCategory}
          >
            Assembly Exceptions
            </AssemblyExceptionButtonModal>
          }


          <EditCustomOptionButtonModal type="primary" 
            onChange={handleAddCustomOption} 
            disabled={!!getCategoryDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getCategoryDisabledMsg())}
            categoryId={selectedCategory?.id}
          >
            Add Custom Option
            </EditCustomOptionButtonModal>
          <BMButton type="primary" key="clearBtn"
            disabled={!!getDebugDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getDebugDisabledMsg())}
            onClick={handleClear}>Clear</BMButton>
          <RuleDebugOutputModal
            category={selectedCategory}
            type="primary"
            key="debugBtn"
            obsoletedOptions={obsoletedOptions}
          />
        </Space>
      </Col>
    </Row>
    {isCategoryDisabled() &&
    <Alert type="info" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={getCategoryDisabledMsg()} />
    }
    {isCategoryWarning() &&
    <Alert type="warning" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={getCategoryWarningMsg()} />
    }
    {(!isCategoryDisabled() && !!disabledSelectedRows?.length ) &&
    <Alert type="error" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={"Some of the selections are no longer valid."}
      action={<><Button onClick={handleClearDisabled}>Clear </Button></>} />
    }
    {showMultipleWarning &&
    <Alert type="error" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={"Multiple selections, including custom options, are not valid for this category."}
       />
    }


    <Table
      data-testid="assemblySelectionTable"
      key={"assemblySelectionTable" + tableKey}
      bordered
      loading={props.loading || optionLstAsync.isLoading()}
      style={{ width: "100%" }}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      onChange={handleTableChange}
      onRow={(record, _rowIndex) => {
        return {
          onDoubleClick: () => handleSelectRow(record)
        };
      }}
      rowKey="key"
      rowSelection={{
        type: showMultiple ? "checkbox" : "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: selectedRows.map(s => s.key ),
        hideSelectAll: true,
        getCheckboxProps: (record) => {
          return { 
            disabled: isRowDisabled(record)
          };
        }
      }}
      scroll={{ x: true }}
    />
  </>
}


const AssemblyDetail = (props:{ 
  assembly:ExtAssembly,
  note:string
  onUpdateNote:(n:string | undefined) => void
  labelStyle:CSSProperties
}) => {
  const {assembly:a} = props;
  const asmLbl = (!!a.label?.length ? a.label : a.bomDescription);

  const { quoteAssemblyExceptionLstAsync } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const isAssemblyException = quoteAssemblyExceptionLstAsync?.val?.filter( ae => ae.assembly.id === props.assembly.id ).length;

  const promptOptionNotes = () => {
    const note = prompt("Enter option notes", props.note || "");
    props.onUpdateNote(note || undefined);
  }

  return <>
    {!!isAssemblyException &&
    <div style={infoTextStyle}>(Exception)</div>
    }
    <div style={warningTextStyle}>
      {a.obsoletedAt && '(Obsolete)'}
    </div>
    <div style={warningTextStyle}>
      {a.incompatible && '(Incompatible)'}
    </div>
    <div style={{ ...props.labelStyle }} >
      <div>
        {asmLbl}, <span style={{whiteSpace:"nowrap"}}>{a.bom}</span>
        {(a.priceDifference || 0) != 0 && 
          <span style={{fontWeight: "600"}}>&nbsp;{Utils.formatMoneyWithSign(a.priceDifference)}</span>
        }
      </div>
      {a.selectionRequiresUserInput && (
        <div style={{ marginTop: "5px" }}>
          <strong>Option Notes: </strong>{" "}
          <span>{props.note || "None"}</span>{" "}
          <EditOutlined onClick={promptOptionNotes} />
        </div>
      )}
    </div>
  </>;
}

const CustomOptionDetail = (props:{ 
  category: Category | undefined
  customOption:CustomOptionType 
  disabled?:boolean
  onChange:(co:CustomOptionType)=>void
  labelStyle:CSSProperties
}) => {

  const { customOption, disabled } = props;
  const configurator = useContext(ConfiguratorContext);

  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

    return <>
      <div style={infoTextStyle}>(Custom Option)</div>
      <div style={{ ...props.labelStyle,}}>

        <EditCustomOptionButtonModal 
          type="text" className="ghostBmButton"
          style={{padding:0}}
          onChange={props.onChange}
          disabled={disabled}
          value={customOption}
          categoryId={props.category?.id}
        >
          <span style={{...btnStyle}}>{customOption.content}</span>
        {(customOption?.upgradePrice || 0 ) != 0 && 
        <span style={{fontWeight: "600"}}>&nbsp;{Utils.formatMoneyWithSign(customOption?.upgradePrice)}</span>
        }
        </EditCustomOptionButtonModal>
        {!!customOption.note?.length &&
          <div style={{fontStyle:"italic"}}>Note: {customOption?.note}</div>
        }
        {!!props.category &&
        <ul className="csl" >
          <li style={{fontStyle:"italic"}}>Lead Time (days): {props.category.leadTimeDays}</li>
          {configurator.isEngineering() &&
            <li style={{fontStyle:"italic"}}>Design Time (weeks): {props.category.designTimeWeeks}</li>}
        </ul>
        }
      </div>
    </>;
}



export default AssemblySelectionTable


