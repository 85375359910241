import {  Badge, Menu, } from "antd";
import {
  SecurityScanOutlined,
  FileOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  ToolOutlined,
  PaperClipOutlined,
  DashboardOutlined,
  PartitionOutlined,
  LogoutOutlined,
  SettingOutlined,
  DollarOutlined,
  CheckOutlined,
  RocketOutlined,
  UserOutlined,
  ProfileOutlined,
  TruckOutlined,
  CalendarOutlined,
  MenuOutlined,
  OneToOneOutlined,
  ApiOutlined,
  ControlOutlined,
  TeamOutlined,
  SolutionOutlined,
  ArrowRightOutlined,
  HolderOutlined,
  MailOutlined,
  FileUnknownOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import {
  Link, useLocation,
} from "react-router-dom";
import { useContext } from "react";
import { ApprovalContext, ConfiguratorContext } from "../context";
import logo from "../assets/images/logo.jpg";
import { Permission } from "../api/models";
import NewQuoteWizard from "./Quote/NewQuoteWizard";
import {useSourcewellContext} from "../contexts/SourcewellCntContext";

const SiteNav = (props: {
  handleSelect?: () => void;
}) => {
  const { handleSelect } = props;

  const context = useContext(ConfiguratorContext);
  const approvalContext = useContext(ApprovalContext);
  const locationContext = useLocation();
  const userInfo = context.userInfo;

  const ApprovalItemLabel = () => {
    return <>
      <Link to="/approvals">Approvals
      &emsp;
      <Badge count={approvalContext.approvalCount} size="default">
      </Badge>
      </Link>
    </>;
  }

  const SourcewellItemLabel = () => {

    const sourcewellCnt = useSourcewellContext().cnt;

    return <>
      <Link to="/sourcewellQuotes">Sourcewell
      &emsp;
      <Badge count={sourcewellCnt} size="default" />
      </Link>
    </>;
  }


  const isDealerManagement = ( context.isDealerManagement() || context.isSalesDesk() || context.isAdmin() || context.isEngineering() || context.isReleaseEngineering() ) && !context.isDealerSales()
  const isEngineering = context.isEngineering() || context.isEngineeringReadOnly();
  const isFinanceAdminEngineering = context.isAdmin() || context.isFinance() || isEngineering;
  const isAdminOrEngineering = context.isAdmin() || isEngineering;

  const menuItems: any[] = [
    isAdminOrEngineering && { key: '/categories', label: <Link to='/categories'>Categories</Link>, icon: <PartitionOutlined style={{ fontSize: '20px' }} /> },
    context.isAdmin() && { key: '/system-config', label: <Link to='/system-config'>System Configuration</Link>, icon: <SettingOutlined style={{ fontSize: '20px' }} /> },
    isAdminOrEngineering && { key: '/operations', label: <Link to='/operations'>Operations</Link>, icon: <SecurityScanOutlined style={{ fontSize: '20px' }} /> },
    (isEngineering || context.isSalesDesk()) && { key: '/shipping-destinations', label: <Link to='/shipping-destinations'>Shipping Destination</Link>, icon: <RocketOutlined style={{ fontSize: '20px' }} /> },
    context.hasPermission(Permission.DASH_COMPONENT_WRITE) && { 
      key: '/dash-component-menu', label: 'Dash Component', icon: <OneToOneOutlined style={{ fontSize: '20px' }} />, children: [
        { key: '/dash-component', label: <Link to='/dash-component'>Dash Component List</Link>, icon: <MenuOutlined style={{ fontSize: '20px' }} /> },
        { key: '/assembly-dash-component',  label: <Link to='/assembly-dash-component'>Assign Dash Component</Link>, icon: <ApiOutlined style={{ fontSize: '20px' }}  />},
        { key: '/dash-template',  label: <Link to='/dash-template'>Dash Layout Template</Link>, icon: <ControlOutlined style={{ fontSize: '20px' }}  />},
      ]
    },
    isAdminOrEngineering && { 
      key: null, label: 'Order Processing', icon: <HolderOutlined style={{ fontSize: '20px' }} />, children: [
        { key: '/vfd-review', label: <Link to='/vfd-review'>VFD Review</Link>, icon: <MenuOutlined style={{ fontSize: '20px' }} /> },
        { key: '/custom-work-review', label: <Link to='/custom-work-review'>Custom Work Review</Link>, icon: <MenuOutlined style={{ fontSize: '20px' }} /> },
        { key: '/engineering-teams', label: <Link to='/engineering-teams'>Engineering Teams</Link>, icon: <TeamOutlined style={{ fontSize: '20px' }} /> },
      ]
    },
    ( isDealerManagement ) && {
      key: null, label: 'Dealer Management', icon: <SettingOutlined style={{ fontSize: '20px' }} />, children: [
        { key: '/dealer', label: <Link to='/dealer'>Dealers</Link>, icon: <PartitionOutlined style={{ fontSize: '20px' }} /> },
        ((context.isDealerManagement() || context.isAdmin() ) && !context.isDealerSales() ) &&
        { key: '/users', label: <Link to='/users'>Users</Link>, icon: <UserOutlined style={{ fontSize: '20px' }} /> },
        { key: '/customer', label: <Link to='/customer'>Customers</Link>, icon: <TeamOutlined style={{ fontSize: '20px' }} /> },
        { key: '/salesSupport', label: <Link to='/salesSupport'>Sales Support</Link>, icon: <UserSwitchOutlined style={{ fontSize: '20px' }} /> },
      ]
    },
    (context.isAdmin() || context.isReleaseEngineering()) && {
      key: null, label: 'Epicor', icon: null, children: [
        { key: '/epicor-bulk-export', label: <Link to='/epicor-bulk-export'>Bulk Export</Link>, icon: <FileDoneOutlined style={{ fontSize: '20px' }}  /> },
        { key: '/epicor-sync-status', label: <Link to='/epicor-sync-status'>Sync Status</Link>, icon: <FileDoneOutlined style={{ fontSize: '20px' }}  /> },
      ]
    },
    isFinanceAdminEngineering && {
      key: null, label: 'Assemblies', icon: null, children: [
        { key: '/assemblies', label: <Link to='/assemblies'>Assembly List</Link>, icon: <FileDoneOutlined style={{ fontSize: '20px' }}  /> },
        { key: '/pending-assemblies', label: <Link to="/pending-assemblies">Assembly (Pending)</Link>, icon: <FileAddOutlined style={{ fontSize: '20px' }}  /> },
        { key: '/assembly-costs', label: <Link to="/assembly-costs">Assembly Costs</Link>, icon: <FileAddOutlined style={{ fontSize: '20px' }}  /> },
        context.hasAnyPermission([Permission.RULES_WRITE, Permission.RULES_READ]) && 
          {key: '/rule-sets', label: <Link to="/rule-sets">Rule Sets</Link>, icon: <ToolOutlined style={{ fontSize: '20px' }} />},
          ],
    },
    context.hasAnyPermission([Permission.MASTER_SCHEDULE_READ, Permission.MASTER_SCHEDULE_WRITE]) &&
      { key: '/master-schedule', label: <Link to="/master-schedule">Master Schedule</Link>, icon: <CalendarOutlined style={{ fontSize: '20px' }}  /> },
    isAdminOrEngineering && { key: '/models', label: <Link to="/models">Models</Link>, icon: <DashboardOutlined style={{ fontSize: '20px' }}  /> },
    { key: '/configurator', 
      label: <NewQuoteWizard type="text" className="navMenuButton">Configurator</NewQuoteWizard>,
      icon: <ToolOutlined style={{ fontSize: '20px' }} /> 
    },
    { key: '/quotes', label: <Link to="/quotes">Quotes</Link>, icon: <PaperClipOutlined style={{ fontSize: '20px' }} /> },
    !context.isDealerSales() && { key: '/orders', label: <Link to="/orders">Orders</Link>, icon: <ProfileOutlined style={{ fontSize: '20px' }} /> },
    ( context.isSourcewellManager() ) && { key: '/sourcewellQuotes', label: <SourcewellItemLabel />, icon: <ProfileOutlined style={{ fontSize: '20px' }} /> },
    ( context.isBts() ) && { key: '/bts', label: <Link to="/bts">BTS</Link>, icon: <ProfileOutlined style={{ fontSize: '20px' }} /> },
    ( context.isLogistics() ) && { key: '/logistics', label: <Link to="/logistics">Logistics</Link>, icon: <TruckOutlined style={{ fontSize: '20px' }} /> },
    ( context.isAdmin() || context.isSalesDesk() ) && { key: '/inventory', label: <Link to="/inventory">Inventory</Link>, icon: <ProfileOutlined style={{ fontSize: '20px' }} /> },
    ( context.isAdmin() || context.isReporting() || context.isSalesDesk() ) && { key: '/reporting', label: <Link to="/reporting">Reporting</Link>, icon: <FileOutlined style={{ fontSize: '20px' }} /> },
    context.isFinance() && { key: '/pricing', label: <Link to="/pricing">Pricing</Link>, icon: <DollarOutlined style={{ fontSize: '20px' }}  /> },
    (context.isAdmin() || isEngineering || context.isSalesManager() || context.isSalesDesk() || context.isProcurement() || context.isFinance() || context.isReleaseEngineering() )
    && {
      key: '/approvals',
      label: <ApprovalItemLabel />,
      icon: <CheckOutlined 
        style={{ fontSize: '20px' }} />
    },
    { key: '/internal-user', label: <Link to="/internal-user">Internal User</Link>, icon: <SolutionOutlined style={{ fontSize: '20px' }}  /> },
    (context.isAdmin() || context.isSalesDesk() ) &&
      { key: '/user-sales-teams', label: <Link to='/user-sales-teams'>Sales Teams</Link>, icon: <TeamOutlined style={{ fontSize: '20px' }} /> },
    context.isInternalSales() && {
      key: null, label: 'Help & Setup', icon: <FileUnknownOutlined style={{ fontSize: '20px' }} />, children: [
        { key: '/workflow', label: <Link to="/workflow">Workflow</Link>, icon: <ArrowRightOutlined style={{ fontSize: '20px' }}  /> },
        { key: '/lifeCycleNotification', label: <Link to="/lifeCycleNotification">Life Cycle Notification</Link>, icon: <MailOutlined style={{ fontSize: '20px' }}  /> },
      ]
    },
    { key: '/logout', label: (<a href={context.api.baseUrl + '/logout'}>Logout</a>), icon: <LogoutOutlined style={{ fontSize: '20px' }} /> },
  ]

  return <>
    <div className="menu-outer-layout">
      <div>
        <div className="menu-logo" >
          <Link to="/">
            <img src={logo} style={{ width: 175, padding: 10 }} alt="" />
          </Link>
        </div>
      <style>
        {`
          .navMenuButton {
            padding: 0px;
            color: rgba(255, 255, 255, 0.65);
          }
          .navMenuButton span {
            transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
          }
          .navMenuButton:hover span {
            font-weight: bold;
            color: white;
          }
          `}
      </style>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItems}
          selectedKeys={["/" + locationContext.pathname.split("/")[1]]}
          onSelect={handleSelect}
        >
        </Menu>
      </div>
      <div>
        <div className="user-info">
          <strong>{userInfo?.name}</strong>
        </div>
        {context.isDealerSales() &&
        <div className="dealer">
          <strong>{userInfo?.dealerName}</strong>
        </div>
        }
      </div>
    </div>
  </>


}
export default SiteNav;

