import styles from './InventoryDetail.module.css'
import Title from "antd/lib/typography/Title";
import { Alert, Badge, Button, Card, Col, Divider, Drawer, DrawerProps, Form, Modal, notification, Row, Skeleton, Space, Spin, Table, Tabs } from "antd";
import axios, { CancelTokenSource } from "axios";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { NotFoundError } from "../api/errors";
import { Approval, ApprovalAction, ApprovalDiff, ApproverRole, AssemblyInfo, AXIOS_CANCEL_MSG, BaseCategory, CommentTopic, CustomOptionType, PricingBreakdown, Quote, QuoteAssemblyException, QuoteAssemblyExceptionType, QuoteComment, Performance, ChangeOrderStep, RevisionType, GlobalAccessRole } from "../api/models";
import QuoteCommentList from "../components/Quote/QuoteCommentList";
import { VfdReviewPanel } from "../components/Quote/QuoteInfoTab";
import { ComponentLocationContents, ConfigurationTabContents, DetailsTabContents, PerformanceContents, PricingTabContents, QuoteHeader} from "../components/Quote/QuoteQuickView";
import { ConfiguratorContext } from "../context";
import QuoteContextProvider, { useQuoteContext } from "../contexts/QuoteContext";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { CheckOutlined } from "@ant-design/icons";
import Utils from '../util/util';
import ApprovalDiffTable from '../components/Table/ApprovalDiffTable';
import dayjs from 'dayjs';
import { ApprovalResult } from './approval_action';
import ApprovalTransitionInfo from '../components/ApprovalTransitionInfo';
import BMButton, { BMButtonProps } from '../components/BMButton';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import EditCustomOptionButtonModal from '../components/EditCustomOptionButtonModal';

const EngineeringViewRoles = [ ApproverRole.ENGINEERING, ApproverRole.RELEASE_ENGINEERING, ApproverRole.PROCUREMENT ];

const ApprovalDetailPage = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const cancelLoadQuoteTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadApprovalTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadQuotePricingTokenSourceRef = useRef<CancelTokenSource>();
  const params = useParams<{approvalId?:string|undefined}>();
  const [showComments, setShowComments] = useState<boolean>(false);
  const [quoteCommentCnt, setQuoteCommentCnt] = useState<number | undefined>();
  const [showApprovalResults, setShowApprovalResults] = useState<boolean>(false);

  const [approval, approvalAsync] = useAsyncState<Approval>();
  const [quote, quoteAsync] = useAsyncState<Quote>();
  const [_pricing, pricingAsync] = useAsyncState<PricingBreakdown>();
  const topics = [ CommentTopic.UserComment, CommentTopic.SystemActivity ]
  const [customOptionLst, customOptionLstAsync] = useAsyncState<CustomOptionType[]>();
  const [quoteAssemblyExceptionLst, quoteAssemblyExceptionLstAsync] = useAsyncState<QuoteAssemblyException[]>();
  const [performance, performanceAsync] = useAsyncState<Performance>();
  const [componentLocations, componentLocationsAsync] = useAsyncState<Record<string, string>>();

  useEffect(() => {
    loadQuoteApproval(approvalAsync, params.approvalId)
    .then(approval => {
        if ( approval ) {
          Promise.all([
            loadQuote( quoteAsync, approval.quoteId, approval.revision ),
            loadFullPricingBreakdown( pricingAsync, approval.quoteId, approval.revision ),
            loadQuoteComments(approval.quoteId),
            loadQuoteAssemblyExceptions(approval.quoteId),
            loadPerformance( approval.quoteId )
          ]).then( ([q, _p, commentLst]) => {
              setQuoteCommentCnt( commentLst?.length );
              loadCustomOptions( q?.displayRevisionId );
              loadComponentLocations( q?.displayRevisionId );
          });
        }
    });
  }, [params.approvalId]);

  const loadQuoteApproval = useCallback(debounce( async (quoteAsync:AsyncState<Approval>, approvalId:string | undefined ): Promise<Approval | undefined> => {  
    if (!approvalId) return;

    if ( cancelLoadApprovalTokenSourceRef.current ) {
      cancelLoadApprovalTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadApprovalTokenSourceRef.current = cancelSource;

    try {
      approvalAsync.setLoading();
      const resp = await configurator.api.getQuoteApproval(approvalId, cancelSource.token);
      cancelLoadApprovalTokenSourceRef.current = undefined;

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      if (e instanceof NotFoundError) {
        quoteAsync.setFail( "Approval not found." );
      }
      else {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get quote approval. " + errorMsg, duration: 500 });
          quoteAsync.setFail(errorMsg);
        }
      }
    }
    return;

  }, 750, {leading:true}), []);


  const loadQuote = useCallback(debounce( async (quoteAsync:AsyncState<Quote>, quoteId:string, revision:number | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    if ( cancelLoadQuoteTokenSourceRef.current ) {
      cancelLoadQuoteTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadQuoteTokenSourceRef.current = cancelSource;


    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuoteByRevision(quoteId, revision, cancelSource.token);
      cancelLoadQuoteTokenSourceRef.current = undefined;

      quoteAsync.setDone(resp.data);

      return resp.data;
    } catch (e:any) {
      if (e instanceof NotFoundError) {
        quoteAsync.setFail( "Quote not found." );
      }
      else {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get quote details. " + errorMsg, duration: 500 });
          quoteAsync.setFail(errorMsg);
        }
      }
    }

    return;
  }, 750, {leading:true}), []);

  const loadQuoteComments = async (quoteId:string) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, {topic: topics});
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }

  const reloadCustomOptions = async () : Promise<CustomOptionType[] | undefined> => {
    return loadCustomOptions( quote?.displayRevisionId );
  }
  const loadCustomOptions = async (quoteRevisionId:number | undefined) : Promise<CustomOptionType[] | undefined> => {
    if ( !quoteRevisionId ) return;

    customOptionLstAsync.setLoading()
    try {
      const resp = await configurator.api.getCustomOptions(quoteRevisionId)
      customOptionLstAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to fetch custom options. " + errorMsg;
      notification.error( { message: msg });
      customOptionLstAsync.setFail(msg);
    }

    return;
  }

  const loadQuoteAssemblyExceptions = async (quoteId:string | undefined) : Promise<QuoteAssemblyException[] | undefined> => {
    if ( !quoteId ) return;

    quoteAssemblyExceptionLstAsync.setLoading()

    try {
      const resp = await configurator.api.fetchQuoteAssemblyExceptions(quoteId);
      quoteAssemblyExceptionLstAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load assembly exceptions. " + errorMsg });
      quoteAssemblyExceptionLstAsync.setFail(e.message);
    };

    return;
  }

  const loadPerformance = async (quoteId:string | undefined, rev?:number | undefined) : Promise<Performance | undefined> => {
    if ( !quoteId?.length ) return;

    try {
      const resp = await configurator.api.fetchQuotePerformance(quoteId, rev);
      performanceAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get performance statistics. " + errorMsg });
      performanceAsync.setFail( e.message );
    }

    return;
  }
  const loadComponentLocations = async (quoteRevisionId:number | undefined) : Promise<Record<string, string> | undefined> => {
    if ( !quoteRevisionId ) return;

    componentLocationsAsync.setLoading()

    try {
      const resp = await configurator.api.fetchComponentLocations(quoteRevisionId);
      componentLocationsAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load component locations. " + errorMsg });
      componentLocationsAsync.setFail(e.message);
    };

    return;
  }


  const loadFullPricingBreakdown = useCallback(debounce( async (quotePricingDetailsAsync:AsyncState<PricingBreakdown>, quoteId:string | undefined, revision:number | undefined ): Promise<PricingBreakdown | undefined> => {  
    if (!quoteId) return;
    if (!revision) return;

    if ( cancelLoadQuotePricingTokenSourceRef.current ) {
      cancelLoadQuotePricingTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadQuotePricingTokenSourceRef.current = cancelSource;

    quotePricingDetailsAsync.isLoading();
    try {
      const resp = await configurator.api.fetchFullPricingBreakdownByQuote(quoteId, revision, cancelSource.token);
      cancelLoadQuotePricingTokenSourceRef.current = undefined;

      quotePricingDetailsAsync.setDone(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update pricing. " + errorMsg });
      quotePricingDetailsAsync.setFail(e.message);
    }

    return;
  }, 400), []);


  const handleApprove = (approval:Approval | undefined) => {
    if ( approval?.action ) {
      approvalAsync.setDone(approval);
      setShowApprovalResults(true);
    }
  }
  const handleReject = (approval:Approval | undefined) => {
    if ( approval?.action ) {
      approvalAsync.setDone(approval);
      setShowApprovalResults(true);
    }
  }

  const getSplitOrderWithoutSubmission = (): string[] => {
    if (!approval?.pendingSplit?.self) return [];
    return [
      ...( !!(approval?.pendingSplit?.selfSubmitted) ? [] : [approval?.pendingSplit?.self]),
      ...(approval?.pendingSplit?.partners?.filter(partner => !partner.partnerSubmitted).map(partner => partner.quoteId) || [])
    ];
  }

  const {
    isSalesChangeOrder,
    isEngineeringChangeOrder,
    isChangeOrderSalesDeskPoStep,
    isChangeOrderSalesDeskReviewStep
  } = Utils.getQuoteState(configurator, quoteAsync, false);

  const isApproverRole = configurator.hasRole( approval?.approverRole ) || configurator.isAdmin();
  const isChangeRequest = isSalesChangeOrder || isEngineeringChangeOrder || approvalAsync?.val?.revisionType === RevisionType.SPLIT_ORDER;
  const isEngineeringView = approval && EngineeringViewRoles.includes( approval.approverRole );
  const isSalesView = approval && !isEngineeringView;

  const showPoConfirm = isChangeOrderSalesDeskPoStep || isChangeOrderSalesDeskReviewStep;

  const allSplitChangeReady = approval?.pendingSplit == undefined || (approval.pendingSplit?.selfSubmitted && approval.pendingSplit?.partners.every(p => !!p.partnerSubmitted));
  const splitNotSubmitted = getSplitOrderWithoutSubmission();

  const getApproveDisabledMsg = () => {
    return !isApproverRole  ? `This approval requires a role of ${Utils.snakeCaseToFirstLetterCapitalized( approval?.approverRole )}`
      : !allSplitChangeReady ? `Some split orders have not been submitted: ${splitNotSubmitted.join(", ")}`
      : approval?.action  ? `This quote has already been ${approval.action.toLowerCase()}.`
      : undefined;
  }

  const getRejectDisabledMsg = () => {

    return !isApproverRole  ? `This approval requires a role of ${Utils.snakeCaseToFirstLetterCapitalized( approval?.approverRole )}`
      : approval?.action  ? `This quote has already been ${approval.action.toLowerCase()}.`
      : undefined;
  }

  const tabItems:any[] = [];
  if (isChangeRequest) tabItems.push({
    key:"changes",
    label: "Changes",
    children: <ChangeOrderDiff quoteId={approval?.quoteId} revision={approval?.revision} approvalAsync={approvalAsync}/>
  });

  tabItems.push( {
    key:"spec",
    label: "Specifications",
    children: <ConfigurationTabContents />
  });

  return <div className="site-layout-background">
    <Title level={2}>Approval Request - <span style={{textTransform: "uppercase"}}>{Utils.formatApprovalType(approval?.approvalType, approval?.reservation)}</span></Title>

    <Skeleton active loading={approvalAsync.isLoading()} >
    <Space direction="vertical" style={{minWidth: "100%"}}>

      {approval?.pendingSplit && 
        <Alert message={
          <span>
            This approval is for split change. It related to quote 
            <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(approval.pendingSplit.self)}>{approval.pendingSplit.self}</Link> 
            {approval.pendingSplit.partners.map(partner =>
              <>
                <span>and</span>
                <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(partner.quoteId)}>{partner.quoteId}</Link>
              </>
            )}
          </span>}
        />}

      <div style={{display: "flex", gap: ".5rem 2rem", flexWrap: "wrap"}}>

        <div className={styles["section"]}>
          <div >Requested:</div>
          <div ><span>{approval?.requestedBy?.name}</span></div>
          <div ><span>{dayjs(approval?.createdAt).format("MMMM Do YYYY")}</span></div>
        </div>

      </div>

      {!!approval?.workflow?.length && <ApprovalTransitionInfo
        quote={quote}
        approval={approval}
        isSingleAction={isChangeRequest}
      />}

      <ApprovalResult  
        approval={approval}
        quoteStatus={quote?.status}
        showApprovalResults={showApprovalResults}
        setShowApprovalResults={setShowApprovalResults}
      />

      <Divider />

      <QuoteContextProvider value={{ quoteAsync, quotePricingDetailsAsync:pricingAsync }}>
        <Row gutter={[20, 20]}>
          <Col span={22}>
            <div style={{display: "flex", flexDirection: "row-reverse", gap: "1rem"}} >
              <ApproveButton type="primary"
                showPoConfirm={showPoConfirm}
                data-testid="approve-btn"
                disabled={!!getApproveDisabledMsg()}
                onDisabledClick={() => Utils.notifyDisabled(getApproveDisabledMsg())}
                value={approval}
                onChange={handleApprove} >
                Approve 
              </ApproveButton>
              <RejectButton danger type="primary" 
                data-testid="reject-btn"
                disabled={!!getRejectDisabledMsg()}
                onDisabledClick={() => Utils.notifyDisabled(getRejectDisabledMsg())}
                value={approval} 
                onChange={handleReject} >
                Reject
              </RejectButton>

              <Button type="primary" onClick={() => setShowComments(true)} style={{marginRight: "2rem"}} >
                Comments<Badge count={quoteCommentCnt} size="small" >&nbsp;</Badge>
              </Button>
            </div>
          </Col>
          <Col span={15}>
            <Space direction='vertical' size="middle">
              {quote && <QuoteHeader  hidePricing={true} />}
              <DetailsTabContents  /> 
              <Card title="Engineering Review" size="small" >
                <VfdReviewPanel includeAllCustomOptions={false} />
              </Card>
            <Row gutter={[20, 20]}>
              {performance && <Col >
                <PerformanceContents performance={performance} /> 
              </Col> }
              {componentLocations && <Col >
                <ComponentLocationContents componentLocations={componentLocations} /> 
              </Col> }
              </Row>
              {isEngineeringView && <>
                {!!quoteAssemblyExceptionLst?.length &&
                  <Card bodyStyle={{padding: 0}} >
                    <QuoteAssemblyExceptionsReview value={quoteAssemblyExceptionLstAsync} />
                  </Card>
                }
              </>}
              {!!customOptionLst?.length &&
                <Card bodyStyle={{padding: 0}} >
                  <CustomOptionsReview value={customOptionLstAsync} onChange={reloadCustomOptions} />
                </Card>
              }
              {isChangeRequest ? <Tabs items={tabItems} /> : <ConfigurationTabContents /> }
            </Space>
          </Col>
          {isSalesView && 
          <Col style={{maxWidth: "25rem"}} >
            <Space direction='vertical'>
              <Card >
                <PricingTabContents />
              </Card>
            </Space>
          </Col>}
          <CommentsDrawer 
            quote={quote}
            topics={topics}
            open={showComments} 
            onClose={() => setShowComments(false)} />
        </Row>
      </QuoteContextProvider>
    </Space>
    </Skeleton>
  </div>
}

const ChangeOrderDiff = (props:{
  quoteId: string | undefined
  revision: number | undefined
  approvalAsync: AsyncState<Approval>
}) => {

  const [approvalDiff, approvalDiffAsync] = useAsyncState<ApprovalDiff>();
  const [partnersDiff, setPartnersDiff] = useState<ApprovalDiff[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const cancelLoadApprovalDiffTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadPartnersDiffTokenSourceRef = useRef<CancelTokenSource>();

  const existSplit = !!props.approvalAsync?.val?.pendingSplit;

  const partnersArray = useMemo(
    () =>
      props.approvalAsync?.val?.pendingSplit?.partners?.map((partner) => ({
        quoteId: partner.quoteId,
        revision: partner.revision,
      })),
    [props.approvalAsync?.val?.pendingSplit?.partners]
  );

  const isLoading = approvalDiffAsync.isLoading() || !approvalDiffAsync.val || (existSplit && !partnersArray?.length);
  
  const loadApprovalDiff = async (approvalDiffAsync:AsyncState<ApprovalDiff>, quoteId: string | undefined, revision: number | undefined): Promise<ApprovalDiff | undefined> => {
    if (!quoteId) return;
    if (!revision) return;

    approvalDiffAsync.isLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadApprovalDiffTokenSourceRef, (token) => 
        configurator.api.diffRevisions(quoteId, revision, undefined, token)
      );

      approvalDiffAsync.setDone(resp?.data);

      return resp?.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({ message: "Failed to fetch approval differences. " + errorMsg });
      approvalDiffAsync.setFail(e.message);
    }

    return;
  };

  const loadPartnersDiff = useCallback(
    async (
      originalQuoteId: string,
      originalRevision: number,
      splitQuoteId: string,
      splitRevision: number
    ): Promise<ApprovalDiff | undefined> => {
      try {
        const resp = await Utils.executeWithCancelToken(cancelLoadPartnersDiffTokenSourceRef, (token) => 
          configurator.api.diffArbitraryRevisions(
            splitQuoteId,
            splitRevision,
            originalQuoteId,
            originalRevision,
            token
          )
        );
        return resp?.data;
      } catch (e: any) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error({
          message: "Failed to fetch split order differences. " + errorMsg,
        });
      }
    },
    [approvalDiffAsync]
  );

  useEffect(() => {
    loadApprovalDiff( approvalDiffAsync, props.quoteId, props.revision ) 
  }, [props.quoteId, props.revision]);

  useEffect(() => {
    const originalQuoteId = props.approvalAsync.val?.pendingSplit?.self;
    const originalRevision = props.approvalAsync.val?.pendingSplit?.selfParentRevision;
  
    const loadAllPartnersDiffs = async () => {
      if (partnersArray?.length && originalQuoteId && originalRevision) {
        const partnerDiffPromises = partnersArray.reduce(async (accPromise, partner) => {
          const acc = await accPromise;
          const diff = await loadPartnersDiff(
            originalQuoteId,
            originalRevision,
            partner.quoteId,
            partner.revision
          );
          if (diff) {
            acc.push(diff);
          }
          return acc;
        }, Promise.resolve([] as ApprovalDiff[]));
  
        const allPartnersDiffs = await partnerDiffPromises;
        setPartnersDiff(allPartnersDiffs);
      }
    };
  
    loadAllPartnersDiffs();
  }, [partnersArray, loadPartnersDiff]);



  if ( Utils.isEmptyDeep(approvalDiff) ) {
    return <>There are no changes in this revision.</>
  }

  const hasPartners = !!partnersArray?.length;

  return <Spin spinning={isLoading}>
      <Space direction='vertical'>
        <LeadTimeWarning assemblies={approvalDiff?.assembliesDiff?.addedAssemblies} />

          <ApprovalDiffTable 
            diff={approvalDiff}
            quoteId={hasPartners ? props.quoteId : undefined}
          />
          {hasPartners && partnersDiff?.map((partnerDiff, i) => <>
            <ApprovalDiffTable 
              diff={partnerDiff }
              quoteId={partnersArray[i].quoteId}
            />
          </>)}

      </Space>
    </Spin>
}

const CommentsDrawer = (props:DrawerProps & {
  quote:Quote | undefined
  topics:CommentTopic[]
}) => {

  return <>
    <Drawer
      {...props}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{props.quote?.partNumberString} Comment(s)</div>
        </div>
      </>}
    >
      <QuoteCommentList topics={props.topics} />
    </Drawer>
  </>
}


const RejectButton = (props:Omit<BMButtonProps, 'onChange' | 'value'> & {
  value:Approval | undefined
  onChange?: (q:Approval | undefined) => void
}) => {

  const {value:b, onChange:a, ...btnProps } = props;
  const [approval, approvalAsync] = useAsyncState<Approval>();

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();
  const actionNotes = Form.useWatch<string | undefined>('actionNotes', form);

  const handleOpen = (open:boolean) => {
    if (open ) {
      approvalAsync.setDone(props.value);
    }
  }

  const handleReject = async () => {
    const approvalId = approval?.id;
    if (!approvalId ) return;

    const formValues = await form.validateFields();
    const approved = await reject( approvalId, formValues );
    if ( approved ) {
      props.onChange?.(approved);
      setIsOpen(false);
    }

  }

  const reject = async (approvalId:number, values:Record<string, any>) : Promise<Approval | undefined> => {

    try{
      approvalAsync.setLoading();
      const resp = await configurator.api.approvalAction(approvalId, {
        ...values,
        action: ApprovalAction.REJECTED
      })

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to reject. " + errorMsg, duration: 500 });
        approvalAsync.setFail(errorMsg);
      }
    }

    return;

  }

  return <>
    <BMButton
      onClick={() => setIsOpen(true)}
      {...btnProps}
    >
      Reject
    </BMButton>
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={handleOpen}
      footer={[
        <Button key="cancel" 
          onClick={() => setIsOpen(false)} >
          Cancel
        </Button>,  
        <BMButton danger key="reject" type="primary" 
          onClick={handleReject} 
          disabled={!actionNotes?.length}
          onDisabledClick={() => form.validateFields()}
        >
          Reject
        </BMButton>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="actionNotes"
          label="Reject Reason (Public)}"
          rules={[{ required: true, message: "A reason is required." }]}
        >
          <TextArea placeholder={'Please provide a reason.'} rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const ApproveButton = (props:Omit<BMButtonProps, 'onChange' | 'value'> & {
  value:Approval | undefined
  onChange?: (q:Approval | undefined) => void
  showPoConfirm?: boolean | undefined;
}) => {

  const {value:b, onChange:a, showPoConfirm, ...btnProps } = props;
  const [approval, approvalAsync] = useAsyncState<Approval>();

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();

  const handleOpen = (open:boolean) => {
    if (open ) {
      approvalAsync.setDone(props.value);
    }
  }

  const handleApprove = async () => {
    const approvalId = approval?.id;
    if (!approvalId ) return;

    const formValues = await form.validateFields();
    const approved = await approve( approvalId, formValues );
    if ( approved ) {
      props.onChange?.(approved);
      setIsOpen(false);
    }

  }

  const approve = async (approvalId:number, values:Record<string, any>) : Promise<Approval | undefined> => {

    try{
      approvalAsync.setLoading();
      const resp = await configurator.api.approvalAction(approvalId, {
        ...values,
        action: ApprovalAction.APPROVED
      })

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to approve. " + errorMsg, duration: 500 });
        approvalAsync.setFail(errorMsg);
      }
    }

    return;

  }

  return <>
    <BMButton
      onClick={() => setIsOpen(true)}
      {...btnProps}
    >
      {!!showPoConfirm ? 'Confirm PO and Approve' : 'Approve'}
    </BMButton>
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={handleOpen}
      footer={[
        <Button key="cancel" 
          onClick={() => setIsOpen(false)} >
          Cancel
        </Button>,  
        <BMButton key="approve" type="primary" 
          loading={approvalAsync.isLoading()}
          onClick={handleApprove} 
        >
          Approve
        </BMButton>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="actionNotes"
          label="Notes (Public, Optional)"
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const CustomOptionsReview = (props:{
  value: AsyncState<CustomOptionType[]>,
  onChange: (co:CustomOptionType) => void
}) => {

  const configurator = useContext(ConfiguratorContext);

  const { value:customOptionLstAsync } = props;
  const customOptionLst = props.value.val;

  const isReadOnly = !(configurator.isEngineering() || configurator.isAdmin());

  const btnStyle = isReadOnly
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <div key="customOptionsReview">
    <style>
      {`
      .dialog-customOptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-customOptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-customOptionLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table
      size="small"
      pagination={{
        hideOnSinglePage:true,
        pageSize: 5,
      }}
      className="dialog-customOptionLst"
      columns={ [ 
        {
          title: "Custom Option",
          render: (co:CustomOptionType) =>
            <EditCustomOptionButtonModal 
              type="text" className="ghostBmButton"
              style={{padding:0}}
              onChange={props.onChange}
              disabled={isReadOnly}
              value={co}
              categoryId={co.category?.id}
            >
              <span style={{...btnStyle}}>{co.content}</span>
            </EditCustomOptionButtonModal>
        },
        {
          title: "Category",
          render: (co:CustomOptionType) => Utils.stripSortingPrefix(co.category?.name)
        },
        {
          title: "MSRP",
          render: (co:CustomOptionType) => Utils.formatMoney(co.msrp, "")
        },
        {
          title: "Selected",
          render: (co:CustomOptionType) => co.included ? <CheckOutlined /> : undefined
        },
      ]}
      rowKey="id"
      loading={customOptionLstAsync?.isLoading()}
      dataSource={customOptionLst}
    />
  </div>;
}

const QuoteAssemblyExceptionsReview = (props:{
  value: AsyncState<QuoteAssemblyException[]>,
  onChange?: (co:QuoteAssemblyException) => void
}) => {

  const { value:quoteAssemblyExceptionLstAsync } = props;
  const quoteAssemblyExceptionLst = props.value.val;

  const getAssemblyLabel = (a:AssemblyInfo) : string | undefined => (!!a.label?.length ? a.label : a.bomDescription);

  return <div key="quoteAssemblyExceptionReview">
    <style>
      {`
      .dialog-quoteAssemblyExceptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-quoteAssemblyExceptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-quoteAssemblyExceptionLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table
      size="small"
      pagination={{
        hideOnSinglePage:true,
        pageSize: 5,
      }}
      className="dialog-quoteAssemblyExceptionLst"
                columns={
                  [ {
                  title: "Assembly Exception",
                  render: (ae:QuoteAssemblyException) => <>
                    <div style={{fontWeight: 600}}>{getAssemblyLabel(ae.assembly)}, <span style={{whiteSpace:"nowrap"}}>{ae.assembly.bom}</span></div>
                    <div >{ae.reason} by {Utils.formatUsername(ae.createdBy)} on {dayjs(ae.createdAt).format("MM/DD/YYYY")}</div>
                  </>
                },
                {
                  title: "Category",
                  render: (ae:QuoteAssemblyException) => Utils.stripSortingPrefix(ae.assembly.categoryName)
                },
                {
                  title: "Type",
                  render: (ae:QuoteAssemblyException) => ae.type == QuoteAssemblyExceptionType.OBSOLETE ? "Obsolete" 
                  : ae.type == QuoteAssemblyExceptionType.RULE_OVERRIDE ? "Rule Override" 
                  : ae.type
                },
                ]}
      rowKey="id"
      loading={quoteAssemblyExceptionLstAsync?.isLoading()}
      dataSource={quoteAssemblyExceptionLst}
    />
  </div>;
}

const LeadTimeWarning = (props:{
  assemblies:AssemblyInfo[] | undefined
}) => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;
  const [_modelCategories, modelCategoriesAsync] = useAsyncState<BaseCategory[]>([]);
  const cancelModelCategoryTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    if ( !quote?.productionDate ) return;

    loadModelCategories(modelCategoriesAsync, quote?.model.id);
  }, [quote?.model.id] );


  const loadModelCategories = useCallback(debounce( async ( modelCategoriesAsync:AsyncState<BaseCategory[]>, modelId:number | undefined ) : Promise<BaseCategory[] | undefined> => {
    const id = modelId;
    if (!id) return;

    if ( cancelModelCategoryTokenSourceRef.current ) {
      cancelModelCategoryTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelModelCategoryTokenSourceRef.current = cancelSource;

    try {
      modelCategoriesAsync.setLoading();
      const resp = await configurator.api.getConfiguratorCategories(id )
      cancelModelCategoryTokenSourceRef.current = undefined;

      const configuratorCategories = resp.data;

      var categories = configuratorCategories
      .filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

      modelCategoriesAsync.setDone( categories );
      return categories;

    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        modelCategoriesAsync.setFail( errorMsg );
      }
    }

    return;
  }, 750), []);

  const changeCategories = new Set(props.assemblies?.map(a => a.category?.id ) || [] );
  const maxLeadTime = modelCategoriesAsync.val?.filter( c => changeCategories.has(c.id) )
    .map( m => m.leadTimeDays )
    .reduce( (acc, v ) => v > acc ? v : acc, 0 ) || 0;
  const daysTillProduction = quote?.productionDate ? dayjs(quote.productionDate).diff(dayjs(), 'day') : 0;

  if ( !quote?.productionDate || maxLeadTime < daysTillProduction ) return <></>;

  return <Alert type="error" message={`Required Lead Time of ${maxLeadTime} days is within ${daysTillProduction} days till production.`} />
}


export default ApprovalDetailPage;

