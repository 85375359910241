import { useContext, useState } from "react";
import { PAGINATION_MAX_PAGE_SIZE, QuoteStatus } from "../../api/models";
import { ConfiguratorContext } from "../../context";
import { Select } from "antd";

export interface Props {
    allowMultiple?: boolean
    quoteStatusFilter?: QuoteStatus,
    onChange?: (quoteIds: number[]) => void;
}

const QuoteSelector = (props: Props) => {
    const configurator = useContext(ConfiguratorContext);
    const [quotes, setQuotes] = useState<any[]>([]);

    async function handleSearch(val: any) {
        if (val.length <= 2) return;

        const resp = (await configurator.api.listQuotes({
            search: val, 
            includingArchived: true, 
            myQuotes: false, 
            page: 0, 
            size: PAGINATION_MAX_PAGE_SIZE, 
            quoteStatus: [props.quoteStatusFilter].filter(v => !!v).map(s => s.toString())
        })).data;
        setQuotes(resp.content);
    }

    return (
        <Select
            onChange={props.onChange}
            showSearch={true}
            optionFilterProp="children"
            mode={props.allowMultiple ? "multiple" : undefined}
            onSearch={handleSearch}
            allowClear={true}
            filterOption={false}
            options={quotes.map( quote => {
                const label = [ quote.name ];
                if ( quote.partNumberString?.length ) label.push( `(${quote.partNumberString})` );
                return { 
                    value:quote.id, 
                    label: label.join(" ") 
                };
            })}
        />
    );
};

export default QuoteSelector;
