import "../../util/mobile-table.css";
import styles from "../orders.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Input, Button, Space, notification, Checkbox, Row, Col, Select, Form, DatePicker, Modal, Tooltip } from "antd";
import  { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../../context";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../../util/util";
import { APPROVALS_REPORT, ApprovalAction, Customer, DateFilterType, PAGINATION_MAX_PAGE_SIZE, Permission, PoNumber, QuoteFilter,
  QuoteStatus, ShippingDestination, SortDirection, Truck, User, Approval, } from "../../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import { getCSVRow } from "../../helpers/csv";
import {Link} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import _ from "lodash";
import QuoteShippingDestinationSelector from "../../components/quote_shippingDestination_selector";
import QuoteEndCustomerSelector from "../../components/quote_endCustomer_selector";
import IncentiveProgramSelector from "../../components/incentive_program_selector";
import DealerMultipleSelector from "../../components/DealerMultipleSelector";
import Paragraph from "antd/es/typography/Paragraph";
import CategorySelector from "../../components/category_selector";
import SaveFilter from "../../components/widgets/SaveFilter";
import useDateTypeAndRangeHook from "../../hook/useDateTypeAndRangeHook";
import axios, {CancelTokenSource} from "axios";

type  ApprovalsReportDataSort = SorterResult<ApprovalsReportData> | SorterResult<ApprovalsReportData>[]
type  TableOnChangeFn = (p:TablePaginationConfig, f:Record<string, FilterValue | null>, s: ApprovalsReportDataSort) => void

const { RangePicker } = DatePicker;

const AXIOS_CANCEL_MSG = "axios.cancel";

export interface ApprovalsReportData {
quoteId                                : string
quoteName                              : string
revision                               : number
partNo                                 : string
epicorRevision                         : string
status                                 : string
quoteApproved                          : boolean
modelName                              : string
quantity                               : number
dealerPrice                             : number
endCustomer                            : Customer | undefined
owner                                  : User | undefined
dealerName                             : string
bmSalesReps?                           : string | undefined
latestApproval?                        : Approval
quoteExpirationDate?                   : Date
productionDate?                        : string | undefined
shippingDate?                          : string | undefined
shippingDestination?                   : ShippingDestination
shippingDestinationPrice?              : number
poNumber?                              : PoNumber
incentivePrograms?                     : string
selectionSummary?                      : { [key: string]: string }, //todo
trucks?                                : Truck[]
gvwr?                                : number
}

interface ExportOptions {
  categories?: number[];
  columns: ColumnType<ApprovalsReportData>[];
  rowPerUnit?: boolean;
}

const DEFAULT_PAGE_SIZE = 10;
const ApprovalsReport = () => {
  const [_quoteList, quoteListAsync] = useAsyncState<ApprovalsReportData[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [hasCustomOptionsParam, setHasCustomOptionsParam] = useQueryParam<boolean|undefined|null>("hasCustomOptions", BooleanParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerIds", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationIds", NumericArrayParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<ExportOptions>();
  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: false,
    includingArchived: false,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    hasCustomOptions: hasCustomOptionsParam || false,
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [sort, setSort] = useState<ApprovalsReportDataSort>({
    columnKey: sortFieldQueryParam || 'quoteId',
    order: sortDirectionQueryParam === 'ascend' ? 'ascend' : 'descend' //for typescript
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
    loadQuotes( quoteListAsync, pagination, filter , sort );
  }, [pagination.pageSize, pagination.current, sort ]);

  const loadQuotes = useCallback(debounce((quoteListAsync:AsyncState<ApprovalsReportData[]>,  pagination: TablePaginationConfig, filter: QuoteFilter | undefined, sorter:ApprovalsReportDataSort ) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "quoteId",
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    quoteListAsync.setLoading();
    configurator.api.fetchApprovalsReport({
      ...filter,
      page: ( pagination.current || 1 ) - 1,
      size: pagination.pageSize || DEFAULT_PAGE_SIZE,
      sort
    }, cancelSource.token )
      .then( resp => {
        quoteListAsync.setDone( resp.data.content );
        setPagination({ ...pagination, total: resp.data.totalElements });
      },
        (e:any) => {
          const id = e.response?.data?.message || e.message ;
          if ( id !== AXIOS_CANCEL_MSG ) {
            quoteListAsync.setFail( e.message );
            notification.error({ message:"Quotes failed to load. " + e.message });
          }
        });
  }, 600 ), [] );

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SorterResult<ApprovalsReportData> | SorterResult<ApprovalsReportData>[]) => {
    setPagination(pagination);

    const firstSort = [ sorter ].flatMap(v => v)[0];
    setSortFieldQueryParam( firstSort.columnKey ? String( firstSort.columnKey ) : undefined );
    setSortDirectionQueryParam( firstSort.order );

    setSort(sorter);
  };

  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = debounce( (_values: Record<string, any>, filter: QuoteFilter) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setDealerFilterParam( filter.dealerLst );
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setOrdersOnlyParam(filter.ordersOnly);
    setHasCustomOptionsParam(filter.hasCustomOptions);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    setPagination({ ...pagination, current: 1 });
    loadQuotes( quoteListAsync, pagination, filter , sort );
    setFilter(filter);
  }, 300);

  const assignTruckStatus = (orderData: OrderData, truckData: TruckData) => {
    const orderStatus = orderData.status === QuoteStatus.SHIPPED ? 
      Utils.snakeCaseToFirstLetterCapitalized(QuoteStatus.ORDER.toString())
      : 
      Utils.snakeCaseToFirstLetterCapitalized(orderData.status);
    orderData.status = truckData.epicorProductionStatus || orderStatus;
  }

  interface TruckData {
    truckSerialNumberStr: string, 
    engineSerialNumber: string,
    transmissionSerialNumber: string, 
    vin: string, 
    epicorShipDate: string,
    epicorProductionDate: string,
    epicorProductionStatus: string,
    shipped: boolean,
    steerAxle1:string;
    steerAxle2:string;
    steerAxle3:string;
    pusherAxle1:string;
    driveAxle1:string;
    driveAxle2:string;
    driveAxle3:string;
    tagAxle:string;
    scr:string;
    dpf:string;
    productionDate:string;
    shipDate:string;
  };

  interface OrderData {
    quoteId: string,
    quoteName: string,
    revision: string,
    partNo: string,
    epicorRevision: string,
    status: string,
    modelName: string,
    quantity: string,
    dealerPrice: string,
    poAmount: string,
    poNumber: string,
    hasDocuments: string,
    incentivePrograms: string,
    endCustomerName: string,
    ownerName: string,
    dealerName: string,
    bmSalesReps: string,
    latestApprovalStatus: string,
    latestApprovalRole: string,
    latestApprovalAction: string,
    latestApprovalDate: string,
    quoteExpirationDate: string,
    productionDate: string,
    shippingDate: string,
    shippingDestinationName: string,
    shippingDestinationPrice: string,
    truckSn: string,
    gvwr: string,
  }

  const getTruckData = (truck: Truck | undefined, rowPerUnit: boolean | undefined): TruckData | undefined => {

    if (!rowPerUnit || !truck) return undefined;

    return {
      truckSerialNumberStr: truck.truckSerialNumberStr || "", 
      engineSerialNumber: truck.engineSerialNumber || "",
      steerAxle1: truck.steerAxle1 || "",
      steerAxle2: truck.steerAxle2 || "",
      steerAxle3: truck.steerAxle3 || "",
      pusherAxle1: truck.pusherAxle1 || "",
      driveAxle1: truck.driveAxle1 || "",
      driveAxle2: truck.driveAxle2 || "",
      driveAxle3: truck.driveAxle3 || "",
      tagAxle: truck.tagAxle || "",
      scr: truck.scr || "",
      dpf: truck.dpf || "",
      transmissionSerialNumber: truck.transmissionSerialNumber || "", 
      vin: truck.vin||'', 
      shipDate: truck.shipDate ? dayjs(truck.shipDate ).format("MM/DD/YYYY") : "",
      productionDate: truck.productionDate ? dayjs(truck.productionDate ).format("MM/DD/YYYY") : "",
      shipped: truck.shipped || "",
      epicorShipDate: truck.epicorShipDate || "",
      epicorProductionDate: truck.epicorProductionDate || "", 
      epicorProductionStatus: truck.epicorProductionStatus || "",
    } as TruckData;
  }

  // Assign column order
  const getTruckRowValue = (truckData: TruckData): string[] =>{
    return [
      truckData.truckSerialNumberStr,
      truckData.engineSerialNumber,
      truckData.transmissionSerialNumber,
      truckData.steerAxle1,
      truckData.steerAxle2,
      truckData.steerAxle3,
      truckData.pusherAxle1,
      truckData.driveAxle1,
      truckData.driveAxle2,
      truckData.driveAxle3,
      truckData.tagAxle,
      truckData.scr,
      truckData.dpf,
      truckData.vin,
      truckData.epicorShipDate,
      truckData.epicorProductionDate,
    ];
  }

  const getQuoteData = (q: ApprovalsReportData, t?: TruckData): OrderData => {
    const truckSn = q.trucks?.map(t => t.truckSerialNumberStr ).join(",") || "";
    return {
      quoteId: q.quoteId || "",
      quoteName: q.quoteName || "",
      revision: String(q.revision),
      partNo: q.partNo || "",
      epicorRevision: q.epicorRevision || "",
      status: q.status || "",
      modelName: q.modelName || "",
      quantity: String(q.quantity),
      dealerPrice: Utils.formatMoney(q.dealerPrice),
      poAmount: Utils.formatMoney(q.poNumber?.amount),
      poNumber: q.poNumber?.poNumber || "",
      hasDocuments: String(!!q?.poNumber?.documents?.length),
      incentivePrograms: q.incentivePrograms || "",
      endCustomerName: q.endCustomer?.name || "",
      ownerName: q.owner?.name || "",
      dealerName: q.dealerName || "",
      bmSalesReps: q.bmSalesReps || "",
      latestApprovalStatus: q.latestApproval?.approvedStatus || "",
      latestApprovalRole: q.latestApproval?.approverRole || "",
      latestApprovalAction: q.latestApproval?.action || "",
      latestApprovalDate: q.latestApproval?.actionedAt ? dayjs(q.latestApproval.actionedAt).format("MM/DD/YYYY") : "Pending",
      quoteExpirationDate: q.quoteExpirationDate ? dayjs(q.quoteExpirationDate).format("MM/DD/YYYY") : "",
      productionDate: t?.productionDate ? t.productionDate : (q.productionDate ? dayjs(q.productionDate).format("MM/DD/YYYY") : ""),
      shippingDate: t?.shipDate ? t.shipDate : (q.shippingDate ? dayjs(q.shippingDate).format("MM/DD/YYYY") : ""),
      shippingDestinationName: q.shippingDestination?.name || "",
      shippingDestinationPrice: Utils.formatMoney(q.shippingDestinationPrice),
      truckSn,
      gvwr: q.gvwr ? String(q.gvwr) : "",
    };
  }

  // Assign column order
  const getOrderRowValue = (processedQuoteData: OrderData, selections: string[]): string[] => {
    return [
      processedQuoteData.quoteId,
      processedQuoteData.quoteName,
      processedQuoteData.revision,
      processedQuoteData.partNo,
      processedQuoteData.epicorRevision,
      processedQuoteData.status,
      processedQuoteData.modelName,
      processedQuoteData.quantity,
      processedQuoteData.dealerPrice,
      processedQuoteData.poAmount,
      processedQuoteData.poNumber,
      processedQuoteData.hasDocuments,
      processedQuoteData.incentivePrograms,
      processedQuoteData.endCustomerName,
      processedQuoteData.ownerName,
      processedQuoteData.dealerName,
      processedQuoteData.bmSalesReps,
      processedQuoteData.latestApprovalStatus,
      processedQuoteData.latestApprovalRole,
      processedQuoteData.latestApprovalAction,
      processedQuoteData.latestApprovalDate,
      processedQuoteData.quoteExpirationDate,
      processedQuoteData.productionDate,
      processedQuoteData.shippingDate,
      processedQuoteData.shippingDestinationName,
      processedQuoteData.shippingDestinationPrice,
      processedQuoteData.truckSn,
      processedQuoteData.gvwr,
    ].concat(selections);
  }
  
  const processAndMapDataInOrder = (q: ApprovalsReportData, categoryColumns: string[], retCsv: string[]) => {

    const selections: string[] = categoryColumns.map(category => q.selectionSummary?.[category] || '');

    // show order only
    if (!showExport?.rowPerUnit) {
      const processedOrderData = getQuoteData(q, undefined);
      const orderRowValue = getOrderRowValue(processedOrderData, selections);
      retCsv.push(getCSVRow(orderRowValue));
    }
    // show truck and order
    else {
      q.trucks?.map(t => getTruckData(t, showExport?.rowPerUnit)).filter(v => !!v)
        .forEach(td => {
          if(!td) return;

          const processedOrderData = getQuoteData(q, td);
          assignTruckStatus(processedOrderData, td); // Update status based on truck shipped or not
          const orderRowValue = getOrderRowValue(processedOrderData, selections);
          const truckRowValue = getTruckRowValue(td);
          const rowData = truckRowValue.concat(orderRowValue);
          retCsv.push(getCSVRow(rowData));
      })
    }
  }


  const getRowValue = (data: ApprovalsReportData[], columns:ColumnType<ApprovalsReportData>[]): string[] => {
    const categoryColumns: Array<string> = Array.from(
      data.reduce((categories, r) => {
        if (r.selectionSummary) {
          Object.keys(r.selectionSummary).forEach(k => categories.add(k));
        }
        return categories;
      }, new Set<string>())
    ).sort();

    const truckColumns = showExport?.rowPerUnit ? ['Unit Number', "Engine Serial Number", "Transmission Serial Number", 'Steer Axle 1', 'Steer Axle 2', 'Steer Axle 3', 
      'Pusher Axle 1', 'Drive Axle 1', 'Drive Axle 2', 'Drive Axle 3', 'Tag Axle', 'SCR / CARB', 'DPF', 'VIN', 'Actual Ship Date', 'Actual Production Date'] : [];

    var csv = [truckColumns.concat(columns.map( c => c.title?.toString() || '' ).concat(categoryColumns)).join(',')];

    data.forEach((q: ApprovalsReportData) => {
      processAndMapDataInOrder(q, categoryColumns, csv);
    });

    return csv;
  }

  const exportReport = async (columns:ColumnType<ApprovalsReportData>[]) => {
    try {
      const sorter = [sort].flat().map( sorter => ({
        field: sorter.columnKey?.toString() || "quoteId",
        direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
      }));

      setIsExporting(true);
      const resp = (await configurator.api.fetchApprovalsReport({
        ...filter,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: sorter,
        includeSelectionSummary: true,
        selectionCategories: showExport?.categories
      })).data;

      const csv = getRowValue(resp.content, columns);

      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'approval-report-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
      setShowExport(undefined);
    }
  };

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!hasCustomOptionsParam && setHasCustomOptionsParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
  }

  return (
    <div className="site-layout-background">
      <Title level={2}>Approvals Report</Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <FilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
        />
        <DesktopTable
          quoteListAsync={quoteListAsync}
          tableOnChange={tableOnChange}
          pagination={pagination}
          exportOrders={(c) => setShowExport({columns: c, rowPerUnit: true})}
        />
      </Space>
      {showExport && 
        <Modal open={true} okText="Export" confirmLoading={isExporting}
          onOk={() => exportReport(showExport.columns)} onCancel={() => setShowExport(undefined)}>
          <Title level={3}>Export Approvals Report</Title>
          <Row>
              <Paragraph>Choose a list of categories below to include selections from in the CSV. By default, no selections will be included.</Paragraph>
          </Row>
          <Row>
              <strong>Row Per Unit: </strong> &nbsp;
              <input style={{display: 'inline'}} type="checkbox" checked={showExport?.rowPerUnit ?? true}  onChange={(e: any) => setShowExport({...showExport, rowPerUnit: e.target.checked})}/>
          </Row>
          <Row>
              <strong>Only Include Categories:</strong>
          </Row>
          <Row style={{marginTop: '25px'}}>
              <CategorySelector style={{ width: '100%' }} mode="multiple" onChange={(cat) => setShowExport({...showExport, categories: cat})} />
          </Row>
        </Modal >
        }
    </div>
  );
};


const FilterControls = (props:{
  filter?: QuoteFilter
  onFilterChange: (_values: Record<string, any>, filter:QuoteFilter) => void
  resetQueryParam?: () => void
}) => {

  const [filterForm] = useForm();
  const configurator = useContext(ConfiguratorContext);

  const getDateRange = (filter: QuoteFilter | undefined) => {
    if (filter?.dateFilterStart && filter?.dateFilterEnd) {
      return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
    }
    return undefined;
  }

  const filterWithDateRange = {...props.filter, dateRange: getDateRange(props.filter)}

  const showClearFilter = !!(Object.values(filterForm.getFieldsValue()).filter(v => v).flat().length);

  const clearFilter = () => {
    let setObject = {...filterForm.getFieldsValue()};
    filterForm.resetFields();

    Object.keys(setObject).forEach(key => {
      setObject[key] = undefined;
    });

    filterForm.setFieldsValue(setObject);
    props.onFilterChange({}, setObject);
    props.resetQueryParam?.();
  }

  const {validateDateFilterType, validateRangePicker, filterChange} = useDateTypeAndRangeHook(filterForm, "dateFilterType", "dateRange", props?.onFilterChange);

  return <>
    <style>
      {`
        .clear-filter-button {
          margin-top: 3px;
          border: 1px solid red !important;
          color: red !important;
        }

        .clear-filter-button:hover {
          margin-top: 3px;
          border: 1px solid red !important;
          color: white !important;
          background-color: red !important;
        }

        .custom-form-item .ant-form-item {
          margin-bottom: 0px !important;
        }
      `}
    </style>
    <Form 
      initialValues={filterWithDateRange}
      form={filterForm} 
      onValuesChange={filterChange}
      layout="vertical"
      className="custom-form-item"
    >
    <div className={styles["filter-controls"]}>
      <div className={styles["line1"]}>
        <Form.Item name="search"  style={{width: "95%"}}>
          <Input
            allowClear
            placeholder="Search by quote name, ID, and more."
          />
        </Form.Item>
      </div>
      {(!configurator.isDealerSales() || configurator.hasPermission(Permission.DEALER_ADMIN_READ)) && <>
        <div className={styles["line1"]} >
          <Form.Item name="dealerLst" style={{width: "95%"}}>
            <DealerMultipleSelector placeholder="Filter by Dealer" />
          </Form.Item>
        </div>
      </>}
      <div className={styles["line2"]}>
        <div>
          <Form.Item
            name="ordersOnly"
            valuePropName="checked"
          >
          <Checkbox>Orders Only</Checkbox>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="hasCustomOptions"
            valuePropName="checked"
          >
          <Checkbox>Has Custom Options</Checkbox>
          </Form.Item>
        </div>
        <div>
          <SaveFilter tableName={APPROVALS_REPORT} size="small" style={{marginTop: "3px"}} />
        </div>
        <div>
          {showClearFilter && <Tooltip title="Reset Filter"><Button size="small" onClick={clearFilter} rootClassName="clear-filter-button">Reset</Button></Tooltip>}
        </div>
      </div>
    </div>

      <Row gutter={12}>
        <Col xs={22} xxl={7}>
          <Form.Item
            name="shippingDestinationId"
            label="Shipping Destination"
          >
            <QuoteShippingDestinationSelector  style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={22} xxl={7}>
          <Form.Item
            name="endCustomerId"
            label="End Customer"
          >
            <QuoteEndCustomerSelector  style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={22} xxl={7}>
          <Form.Item
            name="incentivePrograms"
            label="Incentive Programs"
          >
            <IncentiveProgramSelector style={{ width: '100%' }} />
          </Form.Item>

        </Col>

      </Row>

      <Row gutter={12}>
        <Col xs={8} xxl={7}>
          <Form.Item
            name="dateFilterType"
            label="Date Filter"
            rules={[{ validator: validateDateFilterType }]}
          >
            <Select
              options={Object.keys(DateFilterType).map(e => ({ label: _.capitalize(e), value:e }))}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={14} xxl={14} >
          <Form.Item 
            name="dateRange"
            label="Date Filter Range"
            rules={[{ validator: validateRangePicker }]}
          >
            <RangePicker
              name="range"
              style={{width: "100%"}}
            />
          </Form.Item>
        </Col>

      </Row>
    </Form>
  </>
}

const DesktopTable = (props: {
  quoteListAsync: AsyncState<ApprovalsReportData[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  exportOrders:(columns:ColumnType<ApprovalsReportData>[])=>void
}) => {
  const { quoteListAsync, tableOnChange, pagination, exportOrders, } = props;
  const quoteList = quoteListAsync.val;

  const columns:ColumnType<ApprovalsReportData>[] = [
    {
      title: "Quote",
      key: "quoteId",
      sorter: true,
      dataIndex: "quoteId",
      fixed: "left",
    },
    {
      title: "Name",
      key: "latestRevision.name",
      sorter: true,
      fixed: "left",
      render: (q) => <>
        <Link to={"/configurator/" + encodeURI(q.quoteId)} target="_blank">
          <div className="ellipsis" style={{maxWidth: "25rem"}}>{q.quoteName}</div>
        </Link>
      </>
    },
    {
      title: "Rev",
      fixed: "left",
      dataIndex: "revision"
    },
    {
      title: "Part No.",
      fixed: "left",
      dataIndex: "partNo"
    },
    {
      title: "Epicor Revision",
      dataIndex: "epicorRevision"
    },
    {
      title: "Status",
      key: "status",
      render: (q) => Utils.formatQuoteStatus(q)
    },
    {
      title: "Model",
      sorter: true,
      key: "latestRevision.model.name",
      dataIndex: "modelName",
    },
    {
      title: "# Trucks",
      key: "latestRevision.quantity",
      sorter: true,
      dataIndex: "quantity",
    },
    {
      title: "Dealer Price",
      dataIndex: "dealerPrice",
      render: (q) => Utils.formatMoney( q ),
    },
    {
      title: "PO Value",
      key: "latestRevision.poNumber.amount",
      dataIndex: "poNumber",
      sorter: true,
      render: (q) => Utils.formatMoney( q?.amount )
    },
    {
      title: "PO Number",
      key: "latestRevision.poNumber.poNumber",
      dataIndex: "poNumber",
      sorter: true,
      render: (q) => q?.poNumber,
    },
    {
      title: "Has PO Documents",
      render: (q) => String( !!q?.poNumber?.documents?.length ),
    },
    {
      title: "Incentive Programs",
      dataIndex: "incentivePrograms",
    },
    {
      title: "End Customer",
      key: "latestRevision.endCustomer.name",
      dataIndex: "endCustomer",
      sorter: true,
      render: (q) => q?.name
    },
    {
      title: "Salesperson",
      key: "owner.name",
      sorter: true,
      dataIndex: "owner",
      render: (q) => q?.name
    },
    {
      title: "Dealer",
      key: "owner.dealer.name",
      sorter: true,
      dataIndex: "dealerName",
    },
    {
      title: "BM Sales",
      dataIndex: "bmSalesReps",
    },
    {
      title: "Last Approval Status",
      render: (q) => q.latestApproval?.approvedStatus
    },
    {
      title: "Approver Role",
      render: (q) => q.latestApproval?.approverRole
    },
    {
      title: "Last Approval Action",
      render: (q) => q.latestApproval?.action
    },
    {
      title: "Approval Date",
      render: (q) => q.latestApproval?.actionedAt ? dayjs(q.latestApproval?.actionedAt).format("MM/DD/YYYY") : ""
    },
    {
      title: "Quote Expiration",
      dataIndex: "quoteExpirationDate",
      render: (q) => <span>{q ? dayjs(q).format("MM/DD/YYYY") : ""}</span>
    },
    {
      title: "Order Production",
      key: "latestRevision.productionDate",
      dataIndex: "productionDate",
      render: (q) => <span>{q ? dayjs(q).format("MM/DD/YYYY") : ""}</span>
    },
    {
      title: "Order Shipping",
      key: "latestRevision.shippingDate",
      dataIndex: "shippingDate",
      render: (q) => <span>{q ? dayjs(q).format("MM/DD/YYYY") : ""}</span>
    },
    {
      title: "Destination",
      key: "latestRevision.shippingDestination.name",
      sorter: true,
      dataIndex: "shippingDestination",
      render: (q) => q?.name
    },
    {
      title: "Destination Charge",
      dataIndex: "shippingDestinationPrice",
      render: (q) => Utils.formatMoney(q)
    },
    {
      title: "Trucks",
      render: (q) => q.trucks?.map(t => t.truckSerialNumberStr ).join(",") || ""
    },
    {
      title: "GVWR",
      dataIndex: "gvwr",
    },
  ];

  //quick fix to handle no elements
  const newButtonStyle:any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem" };


  return <>
        <div style={{ position: "relative" }}>
          <Row gutter={12} justify={"end"} style={newButtonStyle}>
            <Col>
              <Button type="primary" onClick={() => exportOrders(columns)}>Export</Button>
            </Col>
          </Row>
          <Table
            loading={quoteListAsync.isLoading()}
            onChange={tableOnChange}
            bordered
            pagination={pagination}
            dataSource={quoteList}
            columns={columns}
            rowKey="quoteId"
            scroll={{ x: true }}
          />
        </div>
    </>
}



export default ApprovalsReport;
